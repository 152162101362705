







































import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import Component from 'vue-class-component'
import { AddProductSelects } from '@/modules/shared/dictionary-store/types';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { Prop, Watch } from 'vue-property-decorator';
import AddNewCampaignBoilerplate from './AddNewCampaignBoilerplate.vue';
import { Campaign } from '../../modules/campaigns/store/types';
import { CodeFormat } from '../../store/types';
// import { required } from 'vuelidate/lib/validators';

Component.registerHooks(['beforeRouteLeave']);
@Component({
  components: { SygniContainerTitle, SygniInput, SygniSelect, SygniCheckbox, SygniDatePicker, SygniRectButton, SygniLinkButton },
  computed: {
    ...mapGetters('dictionary', {
      productSelects: 'addProductsSelects'
    })
  }
})
export default class AddNewCampaignFormStepTwo extends AddNewCampaignBoilerplate {
  @Prop({ default: null }) campaign: Campaign;
  @Prop({ default: null }) validation: any;
  productSelects!: AddProductSelects;

  get hasId() {
    return this.$route?.params?.id ? true : false;
  }

  get productCode() {
    return this.productCodeFormats?.find((format: CodeFormat) => format.value === this.campaignData?.productCodeFormat) || null
  }

  get isProductCodePrefixRequired() {
    return this.productCode ? this.productCode?.prefixRequired : false
  }

  get isProductCodePrefixDisabled() {
    if (!this.isEditable) {
      return true
    }

    return !(this.isProductCodePrefixRequired)
  }


  disabledDate(date: Date) {

    if(!this.campaignData.startDate) {
      return false;
    }

    date = new Date(date);
    const startDate = new Date(this.campaignData.startDate);
    return date <= startDate ? true : false;
  }

  disabledStartDate(date: Date) {
    date = new Date(date)

    return new Date() < date
  }

  scrollToError(): void {
    const error: HTMLDivElement = this.$root.$el.querySelector('.app-content .error') as HTMLDivElement | undefined;
    error?.scrollIntoView({ behavior: 'smooth' });
  }

  setProductCodeFormat(value: any) {
    this.campaignData.productCodeFormat = value;

    this.setCampaignData();
  }

  setProductCodePrefix(value: any) {
    this.campaignData.productCodePrefix = value;

    this.setCampaignData();
  }

  setCampaignData(cb?: any) {
    this.campaignData.data = {
      languages: this.languages,
      headline: this.headline,
      description: this.description,
      textSections: this.textSections,
      galleryPhotos: this.galleryPhotos,
      sectionNames: this.sectionNames,
      translations: this.translations,
    };

    this.$store.commit('campaigns/setCampaignData', this.campaignData);
    if(cb) {
      cb();
    }
  }

  setCampaignType(value: any) {
    const data = _.cloneDeep(this.campaignData);
    data.products[0].type = value;
    data.type = value;

    this.campaignData = data;
    this.$store.commit('campaigns/setCampaignData', this.campaignData);
  }

  beforeMount() {
    this.$store.dispatch('investors/getProductCodeFormats')
    this.loadCampaignData();
  }

  @Watch('isProductCodePrefixRequired') onProductCodePrefixRequiredChange() {
    if (!(this.productCode && this.productCode?.prefixRequired)) {
      this.$nextTick(() => {
        this.campaignData.productCodePrefix = null
      })
    }
  }

  @Watch('campaign', { deep: true, immediate: true }) onCampaignChange() {
    this.campaignData = _.cloneDeep(this.campaign)
  }
}
